import * as React from "react"
import * as Data from "../data"
import { Iframe } from "./iframe"

export default function PlayNGoLauncher() {
  const dispatch = Data.useDispatch()
  const game = Data.useGame()
  const { origin } = new URL(game.launchUrl)

  React.useEffect(() => {
    const unsubscribe = window.GameCommunicator.subscribe((event) => {
      switch (event.type) {
        case "logout": {
          dispatch(Data.closeGame())
          break
        }
        case "reloadGame": {
          window.location.reload()
          break
        }
        default: {
          // eslint-disable-next-line no-console
          console.log(event)
        }
      }
    })
    return () => unsubscribe()
  }, [])

  return (
    <React.Fragment>
      <script src="/scripts/play-n-go-communicator.js" />
      <Iframe />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            var iframe = document.getElementById("game")
            iframe.addEventListener("load", function () {
              GameCommunicator.init(iframe, "${origin}")
            })        
          `,
        }}
      />
      
    </React.Fragment>
  )
}
